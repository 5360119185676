import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/src/components/layout.js";
import { Link } from 'hds-react';
import { withPrefix, navigate } from 'gatsby';
import ExternalLink from '../../components/ExternalLink';
import LeadParagraph from '../../components/LeadParagraph';
import InternalLink from '../../components/InternalLink';
export const SSRGuideline = () => {
  return <Link onClick={event => {
    event.preventDefault();
    navigate('/foundation/guidelines/server-side-rendering');
  }} href={withPrefix('/foundation/guidelines/server-side-rendering')} mdxType="Link">
      guideline
    </Link>;
};
export const _frontmatter = {};
const layoutProps = {
  SSRGuideline,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "getting-started-as-a-developer",
      "style": {
        "position": "relative"
      }
    }}>{`Getting started as a Developer`}<a parentName="h1" {...{
        "href": "#getting-started-as-a-developer",
        "aria-label": "getting started as a developer permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <LeadParagraph mdxType="LeadParagraph">
  Helsinki Design System provides a collection of ready-made components for building accessible websites and
  applications that are in line with the Helsinki City Design Language.
    </LeadParagraph>
    <p>{`The approved implementation techniques in the City of Helsinki are `}<strong parentName="p">{`React, WordPress, and Drupal`}</strong>{`. Currently, HDS offers implementation in HTML and React only. If your project is using WordPress or Drupal, please `}<a parentName="p" {...{
        "href": "#wordpress-and-drupal"
      }}>{`refer below for more information`}</a>{`.`}</p>
    <h2 {...{
      "id": "getting-started",
      "style": {
        "position": "relative"
      }
    }}>{`Getting started`}<a parentName="h2" {...{
        "href": "#getting-started",
        "aria-label": "getting started permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <ol>
      <li parentName="ol">{`Have a look at `}<ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://github.com/City-of-Helsinki/helsinki-design-system" mdxType="ExternalLink">{`Helsinki Design System in GitHub`}</ExternalLink>{`. It is recommended to read the `}<ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://github.com/City-of-Helsinki/helsinki-design-system/blob/master/packages/react/README.md" mdxType="ExternalLink">{`repository README`}</ExternalLink>{` first and then take a peek at the `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/tree/master/packages/react" mdxType="ExternalLink">{`hds-react`}</ExternalLink>{` and `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/tree/master/packages/core" mdxType="ExternalLink">{`hds-core`}</ExternalLink>{` packages.`}</li>
      <li parentName="ol">{`Explore the `}<InternalLink href="/components" mdxType="InternalLink">{`Components documentation`}</InternalLink>{` and `}<ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://city-of-helsinki.github.io/helsinki-design-system/storybook/core/" mdxType="ExternalLink">{`HDS storybook`}</ExternalLink>{` to see what is available and how to use them.`}</li>
      <li parentName="ol">{`If you want to contribute, see the `}<InternalLink href="/getting-started/contributing/before-contributing" mdxType="InternalLink">{`Contributing`}</InternalLink>{` page for more information. Please follow the `}<ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://www.atlassian.com/git/tutorials/comparing-workflows/feature-branch-workflow" mdxType="ExternalLink">{`Git Feature Branch Workflow`}</ExternalLink>{`.`}</li>
      <li parentName="ol">{`Take a look at `}<ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://dev.hel.fi/" mdxType="ExternalLink">{`Develop with Helsinki site`}</ExternalLink>{` for practical details for developing open source code for the City of Helsinki.`}</li>
    </ol>
    <h2 {...{
      "id": "core-styles",
      "style": {
        "position": "relative"
      }
    }}>{`Core styles`}<a parentName="h2" {...{
        "href": "#core-styles",
        "aria-label": "core styles permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`The Core package provides Helsinki brand colours, typography, and base styles as CSS styles, and variables. Basic components are also available in HDS Core.`}</p>
    <ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/tree/master/packages/core" mdxType="ExternalLink">
  hds-core in GitHub
    </ExternalLink>
    <p><a parentName="p" {...{
        "href": "https://www.npmjs.com/package/hds-core"
      }}><img parentName="a" {...{
          "src": "https://nodei.co/npm/hds-core.png?downloads=true&downloadRank=true&stars=true",
          "alt": "https://nodei.co/npm/hds-core.png?downloads=true&downloadRank=true&stars=true"
        }}></img></a></p>
    <h2 {...{
      "id": "react-components",
      "style": {
        "position": "relative"
      }
    }}>{`React components`}<a parentName="h2" {...{
        "href": "#react-components",
        "aria-label": "react components permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`Helsinki Design System React library provides a collection of React components for building websites and applications. Using these components will help developers to rapidly create user interfaces that are in line with the Helsinki City Design Language as well as accessible and consistent in behaviour across applications.`}</p>
    <ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/tree/master/packages/react" mdxType="ExternalLink">
  hds-react in GitHub
    </ExternalLink>
    <p><a parentName="p" {...{
        "href": "https://www.npmjs.com/package/hds-react"
      }}><img parentName="a" {...{
          "src": "https://nodei.co/npm/hds-react.png?downloads=true&downloadRank=true&stars=true",
          "alt": "https://nodei.co/npm/hds-react.png?downloads=true&downloadRank=true&stars=true"
        }}></img></a></p>
    <h3 {...{
      "id": "using-the-react-component-library",
      "style": {
        "position": "relative"
      }
    }}>{`Using the React component library`}<a parentName="h3" {...{
        "href": "#using-the-react-component-library",
        "aria-label": "using the react component library permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "install-the-package",
      "style": {
        "position": "relative"
      }
    }}>{`Install the package`}<a parentName="h4" {...{
        "href": "#install-the-package",
        "aria-label": "install the package permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p><inlineCode parentName="p">{`yarn add hds-react`}</inlineCode></p>
    <h4 {...{
      "id": "import-components",
      "style": {
        "position": "relative"
      }
    }}>{`Import components`}<a parentName="h4" {...{
        "href": "#import-components",
        "aria-label": "import components permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p><inlineCode parentName="p">{`import { TextInput } from "hds-react";`}</inlineCode></p>
    <p>{`or`}</p>
    <p><inlineCode parentName="p">{`import { TextInput } from "hds-react/components/TextInput";`}</inlineCode></p>
    <h4 {...{
      "id": "server-side-rendering",
      "style": {
        "position": "relative"
      }
    }}>{`Server-side rendering`}<a parentName="h4" {...{
        "href": "#server-side-rendering",
        "aria-label": "server side rendering permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>

    <p>{`Using server-side rendering? Check our `}<SSRGuideline mdxType="SSRGuideline" />{` on how to use HDS React components with server-side rendering.`}</p>
    <h2 {...{
      "id": "wordpress-and-drupal",
      "style": {
        "position": "relative"
      }
    }}>{`WordPress and Drupal`}<a parentName="h2" {...{
        "href": "#wordpress-and-drupal",
        "aria-label": "wordpress and drupal permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`While HDS does not currently offer WordPress or Drupal implementations, HDS cooperates with multiple WordPress and Drupal projects in the City of Helsinki. Many of these projects have already implemented HDS components which can be reused in other projects.`}</p>
    <p>{`If your project is using either WordPress or Drupal, please contact `}<a parentName="p" {...{
        "href": "mailto:ketu@hel.fi"
      }}>{`ketu@hel.fi`}</a>{` to learn about available implementations that follow HDS.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      